import React, { FunctionComponent } from 'react';
import Section from "./Section";
import Button from "./Button/Button";
import Heading from "./Heading";
import useTranslations from "./useTranslations";
import LocalizedLink from "./LocalizedLink";

type PromoSectionProps = {
};

const PromoSection: FunctionComponent<PromoSectionProps> = (props) => {
    const { promoSection } = useTranslations()

    return (
        <>
            <Section className={'py-20 bg-black text-white'}>
                <div className={'flex mb-5'}>
                    <div className={'w-full text-center text-white'}>
                        <Heading type='h2'>{promoSection.title1}</Heading>
                        <Heading type='h2'>{promoSection.title2}</Heading>
                    </div>
                </div>
                <div className={'text-center'}>
                    <LocalizedLink to={'/order-form'}>
                        <Button variant='secondary' chevronVariant={'white'}>{promoSection.start}</Button>
                    </LocalizedLink>
                    <LocalizedLink to={'/contact'}>
                        <Button variant='undefined' chevronVariant={'white'} defaultStyle={'font-serif inline-flex items-center rounded-full'} color='primary' className={'text-neutral-200 bg-transparent font-medium px-6'}>{promoSection.contact}</Button>
                    </LocalizedLink>
                </div>

            </Section>
        </>
    );
};

export default PromoSection;
