import React, { FunctionComponent } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Heading from "../components/Heading";
import Button from "../components/Button/Button";
import postImage1 from "../assets/images/postImage1.jpg";
import Section from "../components/Section";
import LocalizedLink from "../components/LocalizedLink";
import useTranslations from '../components/useTranslations'

type FeaturedPostsSectionProps = {
    posts?: any[];
};

const FeaturedPostsSection: FunctionComponent<FeaturedPostsSectionProps> = ({ posts = [] }) => {
    const { pages: { blog } } = useTranslations()

    return (
        <Section className={'py-20 bg-ink-5'}>
            <div className={'flex justify-between mb-10'}>
                <Heading type='h2'>Blog</Heading>
                <LocalizedLink to={'/blogs'}>
                    <Button variant='border'>{blog.all}</Button>
                </LocalizedLink>
            </div>

            <div className={'grid grid-cols-3 gap-5'}>
                {posts.map(({ node: post }, index) => {
                    return (
                        <Link key={`blog-featured-post-${index}`} to={`/${post.frontmatter.locale}/blogs/${post.frontmatter.slug}`}>
                            <div className={''}>
                                <div className="relative w-full flex justify-center items-center"
                                     style={{ paddingTop: "56.25%" }}>
                                    <div
                                        className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center">
                                        <img src={post.frontmatter.featuredimage} alt=""
                                             className="max-w-full max-h-full" />
                                    </div>
                                </div>
                                <p className={'mt-4 text-sm text-ink-60 opacity-50'}>
                                    {post.frontmatter.date}
                                </p>
                                <div className={'mt-2'}>
                                    <Heading type='h5' className={'font-semibold'}>{post.frontmatter.title}</Heading>
                                </div>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </Section>
    );
};

export default FeaturedPostsSection;
