import React, { FunctionComponent, useEffect, useState } from 'react';
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import Section from "../components/Section";
import Button from "../components/Button/Button";
import EnjoyRelax from "../assets/images/enjoy-relax.png";
import SetupImage from "../assets/images/what-we-do/setup.png";
import WindingImage from "../assets/images/what-we-do/winding.png";
import FreelanceImage from "../assets/images/what-we-do/freelance.png";
import ManageBusinessImage from "../assets/images/what-we-do/manage-business.png";
import FeaturedPostsSection from "../sections/FeaturedPostsSection";
import HeroImage from "../assets/images/homepage-hero-image.png";
import WhyUsUnderline from "../assets/images/why-us.png";

import { graphql } from "gatsby";
import Testimonials from "../components/Testimonials";
import Container from "../components/Container";
import Counter from "../components/Counter";
// import FaqsSection from '../components/Faqs/FaqsSection';
import useTranslations from '../components/useTranslations';
import PromoSection from '../components/PromoSection';
import LocalizedLink from '../components/LocalizedLink';

const getWhatImage = (key) => {
    const whatImages = {
        setup: SetupImage,
        winding: WindingImage,
        freelance: FreelanceImage,
        manage: ManageBusinessImage
    }

    if (whatImages[key]) {
        return whatImages[key]
    }

    return whatImages.setup
}

type IndexProps = {
    data?: any
};

const IndexPage: FunctionComponent<IndexProps> = (props) => {
    const { pages: { index } } = useTranslations()
    const [featuredPosts, setFeaturedPosts] = useState<any>([])
    const [faqs, setFaqs] = useState<any>([])

    useEffect(() => {
        if (props.data.indexFeaturedPosts.edges) {
            setFeaturedPosts(props.data.indexFeaturedPosts.edges)
        }
        if (props.data.indexFaqs.edges) {
            setFaqs(props.data.indexFaqs.edges)
        }

    }, [])

    return (
        <>
            <Section className='bg-brand-5 w-full' fullWidth>
                <Hero
                    headline={index.hero.title}
                    subheadline={index.hero.description}
                    heroImage={HeroImage}
                    buttons={(
                        <div>
                            <LocalizedLink to={'/order-form'}>
                                <Button className="mr-8" chevronVariant={'white'}>
                                    {index.hero.actions.start}
                                </Button>
                            </LocalizedLink>
                            <LocalizedLink to='/#what-we-do'>
                                <Button className={'ml-4 text-ink-60 font-medium'} defaultStyle={'font-serif inline-flex items-center rounded-full'} variant={'transparent'} chevronVariant={'ink_60'}>
                                    {index.hero.actions.learn}
                                </Button>
                            </LocalizedLink>
                        </div>
                    )}
                />
            </Section>
            <Section className={'py-20'}>
                <div className={'text-center mb-20'}>
                    <Heading type='h3' className={'text-ink-40'}>{index.offers.subTitle}</Heading>
                    <Heading id={'what-we-do'} type={'h2'}>
                        {index.offers.title}
                    </Heading>
                </div>
                <div className={'grid grid-cols-1 md:grid-cols-2 gap-y-32 gap-x-10'}>
                    {index.offers.items.map((item, index) => (
                        <div className={'flex flex-wrap justify-center sm:justify-start'} key={`index.offers-${index}`}>
                            <div className={'flex w-1/2 sm:w-1/3 justify-center sm:justify-start items-start pr-0 sm:pr-4'}>
                                <img src={getWhatImage(item.image)} alt="" className={'object-cover'} />
                            </div>
                            <div className={'w-full sm:w-2/3 flex flex-wrap content-between'}>
                                <Heading type="h5" className={'font-semibold'}>{item.title}</Heading>
                                <p className={'text-lg text-ink-60 mt-2'}>{item.description}</p>
                                {item.links &&
                                    <ul className={'mt-4 space-y-2 flex'}>
                                        {
                                            item.links.map((link, link_index) =>
                                                <li key={`link-${link_index}`}>
                                                    <LocalizedLink to={link.link}>
                                                        <Button chevronVariant={'white'}>{link.title}</Button>
                                                    </LocalizedLink>
                                                </li>)
                                        }
                                    </ul>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </Section>
            <Section className={'py-20 bg-blue-120 text-white'}>
                <div className={'flex flex-wrap items-center'}>
                    <div className={'w-full md:w-1/2 lg:w-1/3 flex justify-center lg:order-last mb-10 lg:mb-0 ml-auto'}>
                        <img src={EnjoyRelax} alt="" />
                    </div>
                    <div className={'w-full md:w-1/2 lg:w-1/2'}>
                        <Heading type='h3'>{index.relax.subTitle}</Heading>
                        <Heading type='h2' className={'py-4'}>{index.relax.title}</Heading>
                        <p className={'text-white mb-12'}>{index.relax.description}</p>
                        <LocalizedLink to={'/contact'}>
                            <Button variant="secondary" chevronVariant={'white'}>{index.relax.contact}</Button>
                        </LocalizedLink>
                    </div>
                </div>
            </Section>
            {/*<Section className={'py-20'}>*/}
            {/*    <div className={'text-center mb-16'}>*/}
            {/*        <Heading type={'h2'} className={'text-center'}>*/}
            {/*            {index.whyUs.title}*/}
            {/*        </Heading>*/}
            {/*        <img src={WhyUsUnderline} className={'inline-block'} alt="" />*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <Testimonials />*/}
            {/*    </div>*/}
            {/*    <div className={'grid lg:grid-cols-4 gap-x-10 mt-24'}>*/}
            {/*        <div className={'text-center'}>*/}
            {/*            <Counter end={8000} suffix="+" className="text-h2 font-bold font-sans" />*/}
            {/*            <p className={'text-lg'}>{index.whyUs.establishedCompanies}</p>*/}
            {/*        </div>*/}
            {/*        <div className={'text-center'}>*/}
            {/*            <Counter end={26000} suffix="+" className="text-h2 font-bold font-sans" />*/}
            {/*            <p className={'text-lg'}>{index.whyUs.hoursSpent}</p>*/}
            {/*        </div>*/}
            {/*        <div className={'text-center'}>*/}
            {/*            <Counter end={8000} suffix="+" className="text-h2 font-bold font-sans" />*/}
            {/*            <p className={'text-lg'}>{index.whyUs.documentsSigned}</p>*/}
            {/*        </div>*/}
            {/*        <div className={'text-center'}>*/}
            {/*            <Counter end={8000} suffix="+" className="text-h2 font-bold font-sans" />*/}
            {/*            <p className={'text-lg'}>{index.whyUs.customers}</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Section>*/}
            <Container>
                <hr />
            </Container>
            {/* <FaqsSection items={faqs} /> */}
            <FeaturedPostsSection posts={featuredPosts} />
            <PromoSection />
        </>
    );
};

export const query = graphql`
query IndexPage($locale: String){
    indexFaqs: allFaqsNode(filter: {locale: {eq: $locale}, data: {index_faq: {eq: true}}}) {
        edges {
            node {
            data {
                category
                content
                title
            }
            locale
            }
        }
    }
    indexFeaturedPosts: allMdx(filter: {frontmatter: {locale: {eq: $locale}}}) {
        edges {
            node {
                frontmatter {
                    category
                    featuredimage
                    title
                    date(formatString: "DD.MM.YYYY")
                    locale
                    slug
                }
            }
        }
    }
}`

export default IndexPage;
